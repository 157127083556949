
import { defineComponent, computed } from "vue";
import { useIsSignedIn } from "@/utils/utils";

export default defineComponent({
  setup() {
    const isSignedIn = useIsSignedIn();

    return {
      isSignedIn,
    };
  },
});
